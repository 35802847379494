import React from "react";
import { useApiCall, usePageName } from "../../hooks/api_call_hooks";
import { useBaseFetch } from "../../hooks/fetch_hooks";
import { api_auth, NoItem } from "../../utils/helpers";
import { Pagination } from "../../components/pagination";
import { useSingleState } from "../credit_limit_requests/single/credit_request_hook";
import { Loader } from "../../components/loader";
import moment from "moment";

export default function QuickTellerTransaction() {
  usePageName("Quickteller Transaction");
  const status = useSingleState("");
  const [requests, loading, fetchAgain, fetchNP] = useBaseFetch(
    "Quickteller Transaction",
    {},
    () =>
      api_auth().get(
        `/quickTeller/getQuickTellerTransaction?paginate=10&status=${status.get}`,
      ),
    [status.get],
  );

  const callApi = useApiCall();
  const runQuery = (reference) => {
    callApi.call_api(() =>
      api_auth().post("/quickTeller/queryTransaction", {
        reference,
      }),
    );
    fetchAgain();
  };
  return (
    <main>
      {loading && <Loader />}

      <NoItem item={requests?.data ?? []} name={"Quickteller Transaction"} />
      <div className="flex mt-5">
        <select
          className="h-10 bg-transparent px-3 border"
          value={status.get}
          onChange={(e) => status.set(e.currentTarget.value)}
        >
          <option value="">All</option>
          <option value="Pending">Pending</option>
          <option value="Successful">Successful</option>
          <option value="Failed">Failed</option>
        </select>
      </div>
      {requests?.data && (
        <table className="w-full mt-4 text-xs custom-table2 whitespace-no-wrap ">
          <thead className="text-left text-sm">
            <tr>
              <th>Reference</th>
              <th>User</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Actions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {requests?.data?.map((r) => (
              <tr key={r.id}>
                <td>#{r.reference}</td>
                <td>
                  {r?.user?.first_name} {r?.user?.last_name}
                </td>
                <td>
                  <span
                    className="px-4 py-1 rounded-md bg-gray-700"
                    style={{ fontSize: "10px" }}
                  >
                    {r.status.replace("_", " ").toUpperCase()}
                  </span>
                </td>
                <td>{r.amount / 100}</td>
                <td>{moment(r.created_at).format("YYYY/MM/DD")}</td>
                <td>
                  <i
                    className="material-icons"
                    onClick={() => runQuery(r.reference)}
                    style={{ cursor: "pointer" }}
                  >
                    run_circle
                  </i>
                </td>
                {/* <td>
                  <Link to={`/payIn4/${r.id}`}>
                    <i className="material-icons">more_horiz</i>
                  </Link>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Pagination fetchNextPrev={fetchNP} data={requests} />
    </main>
  );
}
