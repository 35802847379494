import React, { lazy, Suspense } from "react";
import { isLoggedIn } from "../utils/helpers";
import { Redirect, Route } from "react-router-dom";
import { SideBar } from "../components/sidebar";
import { AppProvider } from "../contexts/app_context";
import Header from "../components/header";
import QuickTellerTransaction from "./quickteller/quicktellerTransactions";

const Dashboard = lazy(() => import("./dashboard"));

const AddMerchant = lazy(() => import("./merchant/add-merchant"));
const ViewMerchant = lazy(() => import("./merchant/merchants"));
const UpdateMerchant = lazy(() => import("./merchant/edit-merchant"));

const Customers = lazy(() => import("./customer/customers"));
const AddCustomer = lazy(() => import("./customer/add-customer"));
const UpdateCustomer = lazy(() => import("./customer/edit-customer"));

const Banks = lazy(() => import("./bank/banks"));

const SupportTickets = lazy(() => import("./support/support_ticket"));
const SupportDetails = lazy(() => import("./support/support_detail"));

const Withdrawals = lazy(() => import("./withdrawal/withdrawals"));

const Transactions = lazy(() => import("./transactions/transaction"));
const TransactionDetails = lazy(() =>
  import("./transactions/transaction_detail"),
);
const Debits = lazy(() => import("./debits/DirectDebits"));
const Disputes = lazy(() => import("./disputes/disputes"));

export function MainPage() {
  if (!isLoggedIn()) {
    return <Redirect to={"/login"} />;
  }
  return (
    <AppProvider>
      <div className="flex flex-end relative text-white text-xs">
        <SideBar />
        <div className="main-content relative flex-1 max-h-screen overflow-y-auto">
          <Header />
          <div className="mt-24 mx-8">
            <Suspense fallback={<div>Loading...</div>}>
              <Route exact path="/">
                <Redirect to="/dashboard" />
              </Route>
              <Route path={"/dashboard"} component={Dashboard} />
              <Route exact path={"/merchant/add"} component={AddMerchant} />
              <Route exact path={"/merchants"} component={ViewMerchant} />
              <Route
                exact
                path={"/merchant/:merchant_id/edit"}
                component={UpdateMerchant}
              />
              <Route
                exact
                path={"/merchants/:merchant_id/customers"}
                render={(props) => <Customers merchant={true} />}
              />
              <Route
                exact
                path={"/merchant-category"}
                component={lazy(() =>
                  import("../pages/categories/merchant/view-categories"),
                )}
              />
              <Route
                exact
                path={"/merchant-category/add"}
                component={lazy(() =>
                  import("../pages/categories/merchant/add-category"),
                )}
              />
              <Route
                exact
                path={"/merchant-category/edit"}
                component={lazy(() =>
                  import("../pages/categories/merchant/add-category"),
                )}
              />
              <Route
                exact
                path={"/product-category"}
                component={lazy(() =>
                  import("../pages/categories/product/view-categories"),
                )}
              />
              <Route
                exact
                path={"/product-category/add/"}
                component={lazy(() =>
                  import("../pages/categories/product/add-category"),
                )}
              />
              <Route
                exact
                path={"/product-category/edit"}
                component={lazy(() =>
                  import("../pages/categories/product/add-category"),
                )}
              />
              <Route
                exact
                path={"/product-subcategory"}
                component={lazy(() =>
                  import("../pages/categories/productSub/viewSubcategories"),
                )}
              />
              <Route
                exact
                path={"/product-subcategory/add/"}
                component={lazy(() =>
                  import("../pages/categories/productSub/addSubcategory"),
                )}
              />
              <Route
                exact
                path={"/product-subcategory/edit"}
                component={lazy(() =>
                  import("../pages/categories/productSub/addSubcategory"),
                )}
              />

              <Route exact path={"/customers"} component={Customers} />
              <Route exact path={"/customer/add"} component={AddCustomer} />
              <Route
                exact
                path={"/customer/:cust_id/edit"}
                component={UpdateCustomer}
              />

              <Route exact path={"/banks"} component={Banks} />
              <Route exact path={"/debits"} component={Debits} />

              <Route exact path={"/support"} component={SupportTickets} />
              <Route
                exact
                path={"/support/detail/:support_id"}
                component={SupportDetails}
              />

              <Route exact path={"/withdrawals"} component={Withdrawals} />
              <Route exact path={"/quickteller"} component={QuickTellerTransaction} />

              <Route exact path={"/transactions"} component={Transactions} />
              <Route
                exact
                path={"/transaction/detail/:trx_ref"}
                component={TransactionDetails}
              />
              <Route
                exact
                path={"/products"}
                component={lazy(() => import("./products/product"))}
              />
              <Route
                exact
                path={"/reports"}
                component={lazy(() => import("./report/report"))}
              />
              <Route
                exact
                path={"/products/:product_id"}
                component={lazy(() => import("./products/edit-product"))}
              />
              <Route
                exact
                path={"/payments"}
                component={lazy(() =>
                  import("./payment_option/payment_option"),
                )}
              />
              <Route
                exact
                path={"/settings"}
                component={lazy(() => import("./settings/settings"))}
              />
              <Route exact path={"/disputes"} component={Disputes} />
              <Route
                exact
                path={"/email-templates"}
                component={lazy(() =>
                  import("../pages/email_templates/templates"),
                )}
              />
              <Route
                exact
                path={"/email-template/:template_id"}
                component={lazy(() =>
                  import("../pages/email_templates/update_template"),
                )}
              />
              <Route
                exact
                path={"/product-featured"}
                component={lazy(() => import("./products/featured_products"))}
              />
              <Route
                exact
                path={"/credit-limit"}
                component={lazy(() =>
                  import(
                    "./credit_limit_requests/multiple/credit_limit_requests"
                  ),
                )}
              />
              <Route
                exact
                path={"/credit-limit/:id"}
                component={lazy(() =>
                  import(
                    "./credit_limit_requests/single/credit_limit_requests_details"
                  ),
                )}
              />
              <Route
                exact
                path={"/PayIn4"}
                component={lazy(() => import("./payIn4/multiple/PayIn4"))}
              />
              <Route
                exact
                path={"/payIn4/:id"}
                component={lazy(() => import("./payIn4/single/PayIn4_details"))}
              />
            </Suspense>
          </div>
        </div>
      </div>
    </AppProvider>
  );
}
