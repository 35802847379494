import { api_auth, api_normal } from "./helpers";
import moment from "moment";

export function login({ email, password }) {
  const req_body = {
    email: email,
    password: password,
  };
  return api_normal().post("/login", req_body);
}

export function fetchMerchants() {
  return api_auth().get("/get-merchants");
}

export function addMerchant(email, business_name, password) {
  return api_normal().post("/register", {
    type: "merchant",
    email,
    password,
    business_name,
    confirm_password: password,
  });
}

export function addCustomer(email, first_name, last_name, password) {
  return api_normal().post("/register", {
    type: "customer",
    email,
    password,
    first_name,
    last_name,
    confirm_password: password,
  });
}

export function getUserDetails() {
  return api_auth().get("/get-user");
}

export function getCustomers(search, merchant_id) {
  let url = "/customers?paginate=10";
  if (!!search) url += `&search=${search}`;
  if (!!merchant_id) url += `&merchant_id=${merchant_id}`;
  return api_auth().get(url);
}

export function getCustomerDetails(user_id) {
  return api_auth().get("/get-user?user_id=" + user_id);
}

export function changeUserStatus(status, user_id) {
  return api_auth().post("/update-account-status", {
    user_id,
    status,
  });
}

export function changeMerchantFeatureStatus(merchant_id, featured) {
  return api_auth().post("/merchants/add-to-featured", {
    merchant_id,
    featured,
  });
}

export function deleteUser(user_id) {
  return api_auth().delete("/delete-user", {
    data: {
      user_id,
    },
  });
}

export function updateCustomerDetails(body) {
  return api_auth().patch("/update-user-info", body);
}

export function getBanks() {
  return api_auth().get("/bank-details");
}
export function getAllBanks() {
  return api_auth().get("/banks");
}

export function getSupportTickets() {
  return api_auth().get("/supports");
}

export function getSupportDetails(id) {
  return api_auth().get("/supports/" + id);
}

export function getSupportReplies(id) {
  return api_auth().get(`/supports/${id}/replies?paginate=100`);
}

export function replySupportTicket(id, message) {
  return api_auth().post(`/supports/${id}`, {
    message,
  });
}

export function closeSupportTicket(id) {
  return api_auth().patch(`/supports/${id}`, {
    confirm: 1,
  });
}

export function fetchWithdrawals() {
  return api_auth().get("/withdrawals");
}

export function updateWithdrawalRequest(id, status, reason) {
  const body = {
    withdrawal_id: id,
    status,
  };
  if (!!reason) {
    body.message = reason;
  }
  return api_auth().patch("/withdrawal/update", body);
}

export function deleteBankDetails(bank_detail_id, user_id) {
  return api_auth().delete("/banks", {
    data: {
      user_id,
      bank_detail_id,
    },
  });
}

export function verifyBankDetails(bank_detail_id, user_id) {
  return api_auth().patch("/banks/verify", {
    user_id,
    bank_detail_id,
  });
}

export function fetchTransactions(
  status = "",
  start_date,
  end_date,
  search,
  page = 10,
) {
  let url = "/transactions?paginate=" + page;
  if (!!start_date) url += `&start_date=${start_date}`;

  if (!!end_date) url += `&end_date=${end_date}`;

  if (!!status) url += `&status=${status}`;

  if (!!search) url += `&search=${search}`;

  return api_auth().get(url);
}

export function approveCustomerRefunds(ref) {
  return api_auth().patch("/cancellation_payback_settlement", {
    transaction_ref: ref,
  });
}
export function cancelCreditLimit(loan_id) {
  return api_auth().patch("/cancel-credit-limit", {
    loan_id,
  });
}
export function deleteCreditLimit(loan_id) {
  return api_auth().patch("/delete-credit-limit", {
    loan_id,
  });
}

export function fetchTransactionDetails(ref) {
  return api_auth().get(`/transactions/${ref}`);
}

export function fetchTransactionPaymentHistory(ref) {
  return api_auth().get("/payments?paginate=5&transaction_ref=" + ref);
}

export function fetchCustomerPaymentHistory(ref) {
  return api_auth().get("/payments?paginate=5&user_id=" + ref);
}

export function confirmCustomerTransaction(id) {
  return api_auth().patch("/payment/confirm/" + id, {
    status: "successful",
  });
}

export function suspendOrCancelTransaction(ref, suspend = true) {
  const status = suspend ? "suspended" : "cancelled";
  const reason = suspend
    ? "Transaction suspended by Admin"
    : "Transaction cancelled by Admin";
  return api_auth().patch(`/transactions/${ref}/manage`, {
    status,
    reason,
  });
}

export function fetchPaymentOptions() {
  return api_auth().get("/payment_options");
}
export function setGeneralPaymentOptionRules(
  id,
  init_pay,
  is_init_pay_rate,
  max_period,
  max_period_type,
  cancellation_rate,
  cancellation_note,
) {
  return api_auth().patch("/payment_options", {
    initial_payment: init_pay,
    is_initial_payment_rate: is_init_pay_rate,
    // repayment_amount: repay,
    // is_repayment_amount_rate: is_repay_rate,
    max_period_count: max_period,
    max_period_type: max_period_type,
    payment_option_id: id,
    cancellation_payback_rate: cancellation_rate,
    cancellation_note: cancellation_note,
  });
}
export function fetchProducts(search, merchant) {
  let url = "/products?paginate=10";
  if (!!search) url += `&search=${search}`;
  if (!!merchant) url += `&merchant_id=${merchant}`;

  return api_auth().get(url);
}

export function fetchSystemSettings() {
  return api_auth().get("/general_settings");
}
export function updateSystemSettings(
  service_charge_fixed_amount,
  service_charge_rate,
  vat_rate,
  cancellation_payback_max_period,
) {
  return api_auth().patch("/general_settings", {
    service_charge_fixed_amount,
    service_charge_rate,
    vat_rate,
    cancellation_payback_max_period,
  });
}

export function searchMerchant(search) {
  return api_auth().get(
    "/get-merchants?fields=business_logo,business_email,status&search=" +
      search.trim(),
  );
}

export function resetApiKeys(password, merchant_id) {
  return api_auth().patch("/keys", { password, merchant_id, reset: 1 });
}

export function updateMerchantDetails(body) {
  return api_auth().post("/update-merchant-account", body);
}

export function adminVerifyMerchant(user_id, status) {
  return api_auth().patch("/verify-merchant", {
    merchant_id: user_id,
    status,
  });
}

export function fetchDailyPaymentAggregate() {
  const start_date = moment().format("YYYY-MM-DD");
  return api_auth().get(
    `/payments/aggregate?fields=count,sum_service_charge,sum_amount&start_date=${start_date}&end_date=${start_date}`,
  );
}

export function fetchTotalPaymentAggregate() {
  return api_auth().get(
    "/payments/aggregate?fields=count,sum_service_charge,sum_amount",
  );
}

export function fetchTotalPendingPayment() {
  return api_auth().get(
    "/payments/aggregate?fields=count,sum_service_charge,sum_amount&status=pending",
  );
}
export function fetchTotalReceivedPayment() {
  return api_auth().get(
    "/payments/aggregate?fields=count,sum_service_charge,sum_amount&status=successful",
  );
}

export function fetchYesterdayPaymentAggregate() {
  const start_date = moment().subtract(1, "day").format("YYYY-MM-DD");
  return api_auth().get(
    `/payments/aggregate?fields=count,sum_service_charge,sum_amount&start_date=${start_date}&end_date=${start_date}`,
  );
}

export function fetchBefore30DaysPaymentAggregate() {
  const end_date = moment().subtract(30, "days").format("YYYY-MM-DD");
  return api_auth().get(
    `/payments/aggregate?fields=count,sum_service_charge,sum_amount&end_date=${end_date}`,
  );
}

export function fetchDisputes(search) {
  return api_auth().get("/disputes?search=" + search);
}

export function fetchProductCategories() {
  return api_auth().get("/products/categories");
}
export function fetchProductSubcategories() {
  return api_auth().get("/products/subcategories");
}
export function fetchAllProductSubcategories() {
  return api_auth().get("/products/subcategories?no_limit=true");
}
export function fetchMerchantCategories() {
  return api_auth().get("/merchants/categories");
}

export function addMerchantCategory(data) {
  return api_auth().post("/merchants/category", data);
}
export function addProductCategory(data) {
  return api_auth().post("/products/category", data);
}
export function addProductSubcategory(data) {
  return api_auth().post("/products/subcategory", data);
}

export function updateMerchantCategory(id, data) {
  return api_auth().post("/merchants/category/" + id, data);
}

export function updateProductCategory(id, data) {
  return api_auth().post("/products/category/" + id, data);
}
export function updateProductSubcategory(id, data) {
  return api_auth().post("/products/subcategory/" + id, data);
}

export function deleteProductCategory(id) {
  return api_auth().delete("/products/category/" + id);
}
export function deleteProductSubcategory(id) {
  return api_auth().delete("/products/subcategory/" + id);
}
export function deleteMerchantCategory(id) {
  return api_auth().delete("/merchants/category/" + id);
}
export function getIndustries() {
  return api_auth().get("/industries");
}
export function getEmploymentStatuses() {
  return api_auth().get("/employment-statuses");
}
export function fetchSingleProduct(mode, id) {
  return api_auth().get("/product/" + id + "?mode=" + mode);
}
export function updateProduct(
  merchant_id,
  id,
  subcategory_id,
  name,
  quantity,
  price,
  details,
  image,
) {
  const formData = new FormData();
  formData.append("merchant_id", merchant_id);
  formData.append("product_id", id);
  formData.append("subcategory_id", subcategory_id);
  formData.append("name", name);
  formData.append("quantity", quantity);
  formData.append("price", price);
  if (!!details) formData.append("details", details);
  if (!!image) formData.append("image", image);
  
  return api_auth().post("/products/update", formData);
}
export function setProductWalletRules(pay_id, prod_id, active) {
  return api_auth().patch("/products_payment_options", {
    payment_option_ids: pay_id.map((v) => parseInt(v.trim())),
    product_id: prod_id,
    active: active ? 1 : 0,
  });
}
export function setProductPaymentOptions(
  pay_id,
  prod_id,
  init_pay,
  is_init_pay_rate,
  repay,
  is_repay_rate,
  max_period,
  max_period_type,
  cancellation_rate,
  cancellation_note,
) {
  return api_auth().patch("/products_payment_options", {
    payment_option_ids: pay_id.map((v) => parseInt(v.trim())),
    product_id: prod_id,
    initial_payment: init_pay,
    is_initial_payment_rate: is_init_pay_rate,
    repayment_amount: repay,
    is_repayment_amount_rate: is_repay_rate,
    max_period_count: max_period,
    max_period_type: max_period_type,
    cancellation_payback_rate: cancellation_rate,
    cancellation_note: cancellation_note,
  });
}
