import React from "react";

export function Pagination({data, fetchNextPrev}){
    if(!(data.data && data.data.length)){
        return <div></div>
    }
    return (
        <div className="flex justify-center items-center mt-3 mb-8">
            {data.prev_page_url && <button onClick={() => fetchNextPrev(data.prev_page_url)} className="border rounded-l hover:bg-indigo-900 hover:text-white block flex items-center justify-center h-8 w-8"><i className="material-icons">chevron_left</i> </button>}
            {!data.prev_page_url && <button className="opacity-25 border rounded-l block flex items-center justify-center h-8 w-8"><i className="material-icons">chevron_left</i> </button>}
            {data.current_page && <span className="border block flex items-center justify-center h-8 w-12">{data.current_page}</span>}
            {!data.next_page_url && <button className="opacity-25 border rounded-r block flex items-center justify-center h-8 w-8"><i className="material-icons">chevron_right</i> </button>}
            {data.next_page_url && <button onClick={() => fetchNextPrev(data.next_page_url)} className="border rounded-r hover:bg-indigo-900 hover:text-white block flex items-center justify-center h-8 w-8"><i className="material-icons">chevron_right</i> </button>}
        </div>
    )
}