import {useBaseFetch} from "../../../hooks/fetch_hooks";
import {api_auth} from "../../../utils/helpers";
import {useState} from "react";

export const useSingleState = (initialState) => {
    const [data, setData] = useState(initialState)
    return {
        set: setData,
        get: data
    }
}
export const useSingleCreditRequestHook = (id) => {
    const [
        request,
        loading,
        fetchAgain,
    ] = useBaseFetch("Credit limit request", {}, () =>
        api_auth().get(`/credit-limit/${id}`)
    );
    const reject = useSingleState(false)
    const approve = useSingleState(false)
    const decline = useSingleState(false)
    const more_document = useSingleState(false)
    return {
        request: {
            data: request,
            loading,
            fetchAgain,
        },
        reject,
        approve,
        decline,
        more_document
    }
}
