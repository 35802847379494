import React, {useContext, useState} from "react";
import {Link, useHistory} from 'react-router-dom';
import {config} from "../utils/constants";
import {AppContext} from "../contexts/app_context";

export default function Header() {
    const app = useContext(AppContext)
    return (
        <nav className="header fixed z-20 flex justify-between h-20 bg-primary items-center">
            <h2 className="pl-12 md:px-10 font-bold text-2xl whitespace-no-wrap">
                {app.pageName}
            </h2>
            <div className="flex justify-between items-center">
                <div>
                    <h5 className="text-sm">{(app.user &&  app.user.first_name) || 'Cool Admin'}</h5>
                </div>
                <Avatar/>
                <i className="hidden md:block material-icons px-3">mail</i>
                <i className="hidden md:block material-icons mr-2">notifications</i>
            </div>
        </nav>
    )
}

function Avatar() {
    const [drop, setDrop] = useState(false);
    const history = useHistory();
    const logout = () => {
        localStorage.removeItem(config.TOKEN_NAME)
        localStorage.removeItem(config.TOKEN_NAME + '_expiry')
        history.push('/')
    }
    return (
        <div className="relative">
            <div onClick={() => setDrop(!drop)} className="rounded-full cursor-pointer mx-3 flex items-center">
                <div className="cursor-pointer rounded-full h-12 w-12 bg-gray-300 relative">
                    {/*<img className="rounded-full w-full h-full" alt={""} src={user.passport}/>*/}
                </div>
                <i className="material-icons pr-3">expand_more</i>
            </div>
            {drop &&
            <div onMouseLeave={() => setDrop(false)} className="shadow-lg bg-white absolute z-30 ">
                <ul className="py-4 px-3 text-xs whitespace-no-wrap text-black">
                    <li className="py-2 cursor-pointer">Contact Support</li>
                    <li className="py-2 cursor-pointer">Knowledge Base</li>
                    <li onClick={logout} className="py-2 cursor-pointer">Logout</li>
                </ul>
            </div>
            }
        </div>
    )
}
