import React, { useEffect, useState } from "react";

function useNetworkStatusHooks() {
  const [connection, setConnection] = useState(true);
  useEffect(() => {
    setInterval(() => {
      const controller = new AbortController();
      const signal = controller.signal;
      const promise = fetch("https://dns.google/", {
        signal,
        cache: "no-store",
        mode: "no-cors",
      });
      const timeout = setTimeout(() => controller.abort(), 3000);
      promise
        .then(() => {
          clearTimeout(timeout);
          setConnection(true);
        })
        .catch((e) => setConnection(false));
    }, 30000);
  }, []);
  return connection;
}

export function NetworkMonitor() {
  const isOnline = useNetworkStatusHooks();
  return (
    <>
      {!isOnline && (
        <div className="fixed z-50 w-full font-bold top-0 bg-red-700 text-white text-center">
          Offline
        </div>
      )}
    </>
  );
}
