import * as dotenv from "dotenv";
dotenv.config();
// export const config = {
//     API_URL: 'https://staging.odio.com.ng/v1',
//     TOKEN_NAME: 'ODAD_Token'
// }
console.log(process.env?.NODE_ENV)
export const PREDICTION_TYPES = {
  credit_limit: "CREDIT_LIMIT",
  from_credit_limit: "ENJOY_NOW_REQUEST",
  payin4: "PAY_IN_4",
};

const prodConfig = {
  API_URL: "https://api.odiopay.com/api/v1",
};

const devConfig = {
  // API_URL: "https://staging.api.myodiopay.com/v1"
  API_URL: "https://api.odiopay.com/api/v1",
  // API_URL: "http://127.0.0.1:8000/api",
};

export const debitConfig = {
  API_URL: "https://cardchargeapi.herokuapp.com/api",
};

const generalConfig = {
  TOKEN_NAME: "ODAD_Token",
};
export const config = {
  ...generalConfig,
  ...(process.env.NODE_ENV === "production" ? prodConfig : devConfig),
};
export const paystackConfig = {
  ptk: "sk_live_5d0b63acd75d5d104be091f541d32777c33beeda",
  pbk: "pk_live_f114d084406e1b974b0623444d8f4672c3cb7825",

  url: "https://api.paystack.co",
};

export const repaymentConfig = {
  url: "https://cardchargeapi.herokuapp.com/api",
};

export const directDebit = {
  url: " https://loanx.odiopay.com/api/v1",
};

export const GOOGLE_API = "AIzaSyCGPY_hsHcarYRmtuyvZCTOyoRWGN7-JGA";
