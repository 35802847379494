import { config, debitConfig } from "./constants";
import React from "react";
import axios from "axios";
export function isLoggedIn() {
  const expiry =
    new Date().getTime() <
    new Date(localStorage.getItem(config.TOKEN_NAME + "_expiry")).getTime();
  return !!localStorage.getItem(config.TOKEN_NAME) && expiry;
}

export function api_auth() {
  const instance = axios.create({
    baseURL: config.API_URL,
    headers: {
      Authorization: localStorage.getItem(config.TOKEN_NAME)
    }
  });
  instance.interceptors.response.use(undefined, err => {
    if (err.response) {
      if (err.response.status === 401) {
        localStorage.removeItem(config.TOKEN_NAME);
        localStorage.removeItem(config.TOKEN_NAME + "_expiry");
        window.location = "/";
        return;
      }
    }
    return Promise.reject(err);
  });
  return instance;
}
export const fetcher = url =>
  api_auth()
    .get(url)
    .then(res => res.data);

export const api_normal = () => {
  const instance = axios.create({
    baseURL: config.API_URL,
    headers: {
      "Content-Type": "application/json;charset=utf-8"
    }
  });
  return instance;
};

export function debit_auth() {
  const instance = axios.create({
    baseURL: debitConfig.API_URL
    // headers: {
    //     'Authorization': localStorage.getItem(config.TOKEN_NAME)
    // }
  });

  return instance;
}

export const parseErrorToArray = error => {
  const arr = [];
  Object.entries(error).forEach(([k, v]) => {
    arr.push(...v);
  });
  return arr;
};

export const parseToNaira = value => {
  const v = value || 0;
  return (+v).toLocaleString("en-NG", { style: "currency", currency: "NGN" });
};

export const TextStatus = ({ type, children }) => {
  let color = "";
  if (["cancelled"].includes(type)) {
    color = "red";
    return <span style={{ color: color }}>{children || type}</span>;
  } else if (["completed", "verified"].includes(type)) {
    color = "#53ca64";
    return <span style={{ color: color }}>{children || type}</span>;
  } else if (["pending", "suspended", "resolution"].includes(type)) {
    color = "#f1bd73";
    return <span style={{ color: color }}>{children || type}</span>;
  } else {
    return <span>{children || type}</span>;
  }
};

export const NoItem = ({ item, name, absolute = true }) => {
  if (!item || !item.length) {
    return (
      <div
        className={
          (absolute ? "absolute-center" : "py-5") + " text-sm text-center"
        }
      >
        No {name} Available
      </div>
    );
  }
  return <div></div>;
};

export const numberFilterFormat = (num, suffix = true) => {
  try {
    let parts = num.toString().replaceAll(",", "").split(".");
    // Parse the mantissa i.e. the set of numbers before the decimal
    let number = parseInt(parts[0]);
    return suffix
      ? `${NumberFormatter(number)}.${parts[1] || "00"}`
      : NumberFormatter(number);
  } catch (e) {
    return num;
  }
};

function NumberFormatter(number, locale) {
  return number.toLocaleString(locale);
}
