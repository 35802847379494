import React, {useState} from 'react';
import {useAlert} from "react-alert";
import {login} from "../../utils/api";
import {config} from '../../utils/constants';
import {Loader} from "../../components/loader";
export default function LoginPage({history}) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const alert = useAlert()
    const submit = async e => {
        e.preventDefault()
        try {
            setLoading(true)
            const response = await login({email, password})
            if(response.data){
                if(response.data.user.type === 'admin'){
                    localStorage.setItem(config.TOKEN_NAME, response.data.token_type + ' ' + response.data.token)
                    localStorage.setItem(config.TOKEN_NAME + '_expiry', response.data.expires_in_sec)
                    alert.success(response.data.message)
                    history.push('/dashboard')
                }else{
                    alert.error('Not an admin')
                }
            }
        } catch (e) {
            if (e.response) {
                alert.error(e.response.data.message)
            }
        } finally {
            setLoading(false)
        }
    }
    return (
        <main className="bg-primary text-center flex items-center justify-center h-screen w-full">
            {loading && <Loader/>}
            <div>
                <h1 className="text-5xl font-bold">Odio</h1>
                <h4 className="text-xl font-semibold mt-5 mb-2">Sign In</h4>
                <p className="text-sm font-hairline px-5 mb-8">Enter your details to login to your odio dashboard</p>

                <form onSubmit={submit}>
                    <div
                        className="h-8 rounded-full bg-login-input my-4 py-2 px-3 flex items-center">
                        <i className="material-icons text-xl">person_outline</i>
                        <input value={email} onChange={e => setEmail(e.currentTarget.value)} required type="email"
                               className="bg-transparent text-xs ml-3 outline-none w-full focus:outline-none"
                               placeholder="Email"/>
                    </div>

                    <div
                        className="h-8 rounded-full bg-login-input w-full my-2 py-2 px-3 flex items-center">
                        <i className="material-icons text-xl">lock_outline</i>
                        <input value={password} onChange={e => setPassword(e.currentTarget.value)} required
                               type="password"
                               className="bg-transparent text-xs ml-3 outline-none w-full focus:outline-none"
                               placeholder="Password"/>
                    </div>

                    <button
                        className="bg-login-button text-xl text-white rounded-full py-2 px-16 my-2 font-hairline">LOGIN
                    </button>
                </form>

            </div>
        </main>
    )
}