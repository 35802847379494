import React, {createContext, useState} from "react";
import {useUserDetails} from "../hooks/fetch_hooks";

export const AppContext = createContext();

export function AppProvider(props){
    const [pageName, setPageName] = useState('')
    const [user, user_fetching, fetchUserDetails] = useUserDetails()

    return <AppContext.Provider value={{
        pageName, setPageName,
        user, user_fetching, fetchUserDetails,
    }}>
        {props.children}
    </AppContext.Provider>
}