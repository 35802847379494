import React from "react";
import { Link, useHistory } from "react-router-dom";

export function SideBar() {
  return (
    <aside className="max-h-screen bg-primary w-64 overflow-y-auto">
      <div className="h-full">
        <h2 className="text-4xl px-5 mt-3 mb-8">Odio</h2>
        <ul className="text-base font-hairline text-primary sidebar">
          <SidebarItem
            to={"/dashboard"}
            icon={"dashboard"}
            title={"Dashboard"}
          />
          <SidebarItem
            to={"/customers"}
            icon={"account_circle"}
            title={"Customers"}
          />
          <SidebarItem
            to={"/credit-limit"}
            icon={"request_quote"}
            title={"Credit Limit Requests"}
          />
          <SidebarItem
            to={"/payIn4"}
            icon={"request_quote"}
            title={"Pay In 4"}
          />
          <SidebarItem
            to={"/debits"}
            icon={"request_quote"}
            title={"Direct Debits"}
          />
          <SidebarItem to={"/merchants"} icon={"store"} title={"Merchants"} />
          <SidebarItem
            to={"/merchant-category"}
            icon={"category"}
            title={"Merchant Categories"}
          />
          <SidebarItem
            to={"/products"}
            icon={"shopping_cart"}
            title={"Products"}
          />
          <SidebarItem
            to={"/product-featured"}
            icon={"local_offer"}
            title={"Featured Products"}
          />
          <SidebarItem
            to={"/product-category"}
            icon={"category"}
            title={"Product Categories"}
          />
          <SidebarItem
            to={"/product-subcategory"}
            icon={"category"}
            title={"Product Subcategories"}
          />
          <SidebarItem
            to={"/transactions"}
            icon={"credit_card"}
            title={"Transactions"}
          />
          <SidebarItem
            to={"/payments"}
            icon={"monetization_on"}
            title={"Payment Options"}
          />
          <SidebarItem
            to={"/withdrawals"}
            icon={"account_balance_wallet"}
            title={"Withdrawals"}
          />
          <SidebarItem
            to={"/quickteller"}
            icon={"receipt_long"}
            title={"Quick Teller"}
          />
          <SidebarItem
            to={"/disputes"}
            icon={"remove_circle"}
            title={"Disputes"}
          />
          <SidebarItem to={"/banks"} icon={"account_balance"} title={"Banks"} />
          <SidebarItem to={"/reports"} icon={"insert_chart"} title={"Report"} />
          <SidebarItem
            to={"/email-templates"}
            icon={"mail"}
            title={"Email Templates"}
          />
          <SidebarItem to={"/settings"} icon={"settings"} title={"Settings"} />
          <SidebarItem to={"/support"} icon={"chat_bubble"} title={"Support"} />
        </ul>
      </div>
    </aside>
  );
}

function SidebarItem({ to, icon, title }) {
  const history = useHistory();

  return (
    <Link to={to}>
      <li
        className={
          to.startsWith("/" + history.location.pathname.split("/")[1])
            ? "active"
            : ""
        }
      >
        <i className="material-icons">{icon}</i>
        {title}
      </li>
    </Link>
  );
}
