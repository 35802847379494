/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from "react";
import { useAlert } from "react-alert";
import { api_auth } from "../utils/helpers";
import {
  getBanks,
  getCustomers,
  getUserDetails,
  getSupportTickets,
  getSupportDetails,
  getSupportReplies,
  fetchWithdrawals,
  fetchTransactions,
  fetchTransactionDetails,
  fetchTransactionPaymentHistory,
  fetchPaymentOptions,
  fetchProducts,
  fetchSystemSettings,
  searchMerchant,
  getCustomerDetails,
  fetchDailyPaymentAggregate,
  fetchTotalPaymentAggregate,
  fetchTotalPendingPayment,
  fetchTotalReceivedPayment,
  fetchYesterdayPaymentAggregate,
  fetchBefore30DaysPaymentAggregate,
  fetchDisputes,
  fetchProductCategories,
  fetchMerchantCategories,
  fetchSingleProduct,
  fetchProductSubcategories,
} from "../utils/api";

export function useBaseFetch(
  name,
  baseInitial,
  fetchX,
  dep_load = [],
  alertN = true,
  dep_no_load = [],
) {
  const [isLoading, showLoading] = useState(true);
  const [base, setBase] = useState(baseInitial);
  const alert = useAlert();
  const firstLoad = useRef(true);

  useEffect(() => {
    fetchAgain(true);
  }, [...dep_load]);
  useEffect(() => {
    if (firstLoad.current) {
      firstLoad.current = false;
    } else {
      fetchAgain(false);
    }
  }, [...dep_no_load]);
  async function fetchAgain(should_load) {
    try {
      if (should_load) {
        showLoading(true);
      }
      const response = await fetchX();
      if (response.data) {
        setBase(response.data.data);
      }
    } catch (e) {
      console.log(e);
      if (alertN) {
        alert.error("Unable to fetch " + name);
      }
    } finally {
      if (should_load) {
        showLoading(false);
      }
    }
  }
  async function fetchNextOrPrev(url) {
    try {
      const urlObj = new URL(url);

      if (urlObj.protocol === "http:") {
        urlObj.protocol = "https:";
      }
      showLoading(true);
      const response = await api_auth().get(urlObj);
      if (response.data) {
        setBase(response.data.data);
      }
    } catch (e) {
      console.log(e);
      alert.error("Unable to fetch" + name);
    } finally {
      showLoading(false);
    }
  }
  return [base, isLoading, fetchAgain, fetchNextOrPrev];
}

export function useMerchants(search) {
  return useBaseFetch("All Merchants", {}, () => searchMerchant(search), [
    search,
  ]);
}

export function useUserDetails() {
  return useBaseFetch("Your details", {}, getUserDetails);
}

export function useCustomers(search, merchant_id) {
  return useBaseFetch(
    "Customers",
    {},
    () => getCustomers(search, merchant_id),
    [merchant_id],
    true,
    [search],
  );
}
export function useBankDetail() {
  return useBaseFetch("Bank Details", {}, getBanks);
}

export function useSupportTickets() {
  return useBaseFetch("Support Tickets", {}, getSupportTickets);
}

export function useSupportDetails(id) {
  return useBaseFetch("Support Ticket Details", {}, () =>
    getSupportDetails(id),
  );
}

export function useSupportReplies(id) {
  return useBaseFetch("Support Ticket Replies", {}, () =>
    getSupportReplies(id),
  );
}

export function useWithdrawals() {
  return useBaseFetch("Withdrawals", {}, fetchWithdrawals);
}

export function useTransactions(start_date, end_date, status, search) {
  return useBaseFetch(
    "Transactions",
    {},
    () => fetchTransactions(status, start_date, end_date, search),
    [status, start_date, end_date],
    true,
    [search],
  );
}

export function useTransactionDetails(ref) {
  return useBaseFetch("Transaction details", {}, () =>
    fetchTransactionDetails(ref),
  );
}

export function useTransactionPaymentHistory(ref) {
  return useBaseFetch("Payment History", {}, () =>
    fetchTransactionPaymentHistory(ref),
  );
}
export function usePaymentOptions() {
  return useBaseFetch("Payment Options", [], fetchPaymentOptions);
}

export function useProducts(search, merchant) {
  return useBaseFetch(
    "Products",
    {},
    () => fetchProducts(search, merchant),
    [merchant],
    true,
    [search],
  );
}

export function useSystemSettings() {
  return useBaseFetch(
    "System Settings",
    {
      service_charge_fixed_amount: 0,
      service_charge_rate: 0,
      vat_rate: 0,
      cancellation_payback_max_period: "0 days",
    },
    fetchSystemSettings,
  );
}

export function useSearchFunction(search, searchFunction) {
  return useBaseFetch("", {}, () => searchFunction(search), [], true, [search]);
}

export function useCustomerDetails(user_id) {
  return useBaseFetch(
    "Customer Details",
    {},
    () => getCustomerDetails(user_id),
    [user_id],
  );
}

export function useDisputes(search) {
  return useBaseFetch("Disputes", {}, () => fetchDisputes(search), [], true, [
    search,
  ]);
}

export function useProductCategories() {
  return useBaseFetch("Product categories", {}, fetchProductCategories);
}
export function useProductSubcategories() {
  return useBaseFetch("Product subcategories", {}, fetchProductSubcategories);
}
export function useMerchantCategories() {
  return useBaseFetch("Merchant categories", {}, fetchMerchantCategories);
}

const calculate_increment = (now, before) => {
  const n = now || 0;
  const b = before || 0;
  if (n === 0) {
    return 0;
  } else {
    const inc = ((n - b) / b) * 100;
    return Math.round(inc);
  }
};
export function useDashboard() {
  const [dailyPayment] = useBaseFetch(
    "Daily Revenue",
    {},
    fetchDailyPaymentAggregate,
  );
  const [totalPayment] = useBaseFetch(
    "Total Revenue",
    {},
    fetchTotalPaymentAggregate,
  );
  const [totalPendingPayment] = useBaseFetch(
    "Total Pending Payment",
    {},
    fetchTotalPendingPayment,
  );
  const [totalReceivedPayment] = useBaseFetch(
    "Total Received Payment",
    {},
    fetchTotalReceivedPayment,
  );
  const [yesterdayPayment] = useBaseFetch(
    "",
    {},
    fetchYesterdayPaymentAggregate,
  );
  const [before30Days] = useBaseFetch(
    "",
    {},
    fetchBefore30DaysPaymentAggregate,
  );

  const data = {
    daily_revenue: dailyPayment.sum_amount || 0,
    daily_increment: calculate_increment(
      dailyPayment.sum_amount,
      yesterdayPayment.sum_amount,
    ),

    total_revenue: totalPayment.sum_amount || 0,
    total_increment: calculate_increment(
      totalPayment.sum_amount,
      before30Days.sum_amount,
    ),

    total_pending_payment: totalPendingPayment.count || 0,

    total_received_payment: totalReceivedPayment.count || 0,

    payment_received:
      totalReceivedPayment.sum_amount +
        totalReceivedPayment.sum_service_charge || 0,
    payment_pending:
      totalPendingPayment.sum_amount + totalPendingPayment.sum_service_charge ||
      0,
  };

  return [data];
}
export function useProduct(mode, id, alertN) {
  return useBaseFetch(
    "product",
    {},
    () => fetchSingleProduct(mode, id),
    [mode],
    alertN,
  );
}
