import React from "react";
import "./styles/tailwind.css";
import "./styles/index.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import Login from "./pages/auth/login.js";
import { MainPage } from "./pages/Main";
import { NetworkMonitor } from "./components/network_monitor";

const options = {
    timeout: 3000,
    position: positions.TOP_CENTER,
    transition: "scale",
};

function App() {
    return (
        <div className="bg-primary-accent">
            <Provider {...options} template={AlertTemplate}>
                <NetworkMonitor />
                <Router>
                    <Switch>
                        <Route exact path="/login" component={Login} />
                        <MainPage />
                    </Switch>
                </Router>
            </Provider>
        </div>
    );
}

export default App;
