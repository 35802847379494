import { useState, useEffect, useContext } from "react";
import { useAlert } from "react-alert";
import { AppContext } from "../contexts/app_context";

export function useApiCall() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const alert = useAlert();
  async function call_api(func, successCallback, errorCallback) {
    try {
      setError({});
      setLoading(true);
      const response = await func();
      if (response.data) {
        alert.success(response.data.message);
        if (!!successCallback) {
          await successCallback(response.data);
        }
      }
      await setTimeout(() => {}, 5000);
    } catch (e) {
      if (e.response) {
        alert.error(e.response.data.message);
        if (e.response.data.error) {
          setError(e.response.data.error);
        }
        if (!!errorCallback) {
          await errorCallback(e.response.data);
        }
      }
    } finally {
      setLoading(false);
    }
  }
  return { call_api, loading, validationError: error };
}

export function usePageName(pageName) {
  const app = useContext(AppContext);
  useEffect(() => {
    app.setPageName(pageName);
    return () => app.setPageName('Dashboard')
  }, [pageName, app]);
}
